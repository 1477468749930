<template>
    <div class="details-container" v-if="getOrderNumber !== ''">
        <details-header />
        <details-status />
        <details-phone v-if="getCanSendSMS && !getIsFinishedStatus" />
        <details-footer />
    </div>
</template>

<script>
    import DetailsFooter from "@/components/DetailsFooter.vue";
    import DetailsHeader from "@/components/DetailsHeader.vue";
    import DetailsStatus from "@/components/DetailsStatus.vue";
    import DetailsPhone from "@/components/DetailsPhone.vue";

    import { mapGetters, mapActions } from "vuex";

    export default {
        name: "Details",
        components: {
            DetailsFooter,
            DetailsHeader,
            DetailsPhone,
            DetailsStatus,
        },
        data() {
            return {
                dataTimer: null,
            };
        },
        computed: {
            ...mapGetters([
                "getOrderNumber",
                "getCanSendSMS",
                "getIsFinishedStatus",
            ]),
        },
        methods: {
            ...mapActions(["setOrderDetails", "setToken"]),
            reloadDataHandler() {
                console.log("reloadDataHandler");
                let globalThis = this;
                this.dataTimer = setInterval(() => {
                    this.setOrderDetails(this.$route.params.orderNumber).then(
                        (data) => {
                            if (!data) {
                                globalThis.$router.push({ name: "Home" });
                            }
                        }
                    );
                }, 10 * 3000);
            },
        },
        mounted() {
            this.setToken();
            if (this.getOrderNumber == "") {
                if (this.$route.params.orderNumber) {
                    let globalThis = this;
                    this.setOrderDetails(this.$route.params.orderNumber).then(
                        (data) => {
                            if (!data) {
                                globalThis.$router.push({ name: "Home" });
                            } else {
                                globalThis.reloadDataHandler();
                            }
                        }
                    );
                } else {
                    this.$router.push({ name: "Home" });
                }
            } else {
                this.reloadDataHandler();
            }
        },
        destroyed() {
            if (this.dataTimer) {
                clearInterval(this.dataTimer);
                this.dataTimer = null;
            }
        },
    };
</script>