<template>
  <footer class="details-container-footer">
    <img src="@/assets/images/ridaLogo.svg" />
  </footer>
</template>

<script>
export default {
  name: "DetailsFooter",
};
</script>
