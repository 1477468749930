<template>
    <div class="details-container-header">
        <i class="far fa-chevron-left" @click="backHomePageHandler"></i>
        <h1>{{ getOrderNumber }}</h1>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "DetailsHeader",
        computed: {
            ...mapGetters(["getOrderNumber"]),
        },
        methods: {
            backHomePageHandler: function () {
                this.$router.push({ name: "Home" });
            },
        },
    };
</script>