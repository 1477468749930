<template>
  <div class="details-container-phone">
    <div class="details-container-phone-label">
      <span>
        Wpisz numer telefonu aby <br />
        otrzymać SMS po zakończeniu naprawy:
      </span>
    </div>
    <dx-validation-group :ref="`validationGroup`">
      <div class="details-container-phone-box">
        <dx-text-box
          height="42px"
          placeholder="Numer telefonu..."
          width="85%"
          mode="tel"
          mask="+48 000 000 000"
          mask-invalid-message="Pole wymagane"
          @keyUp="inputPhoneNumberHandler"
          value-change-event="keyup"
          v-model="phoneNumber"
        >
          <dx-validator>
            <dx-required-rule message="Pole wymagane" />
          </dx-validator>
        </dx-text-box>
        <div
          :class="[
            isValidNumber
              ? 'details-container-phone-box-btn active'
              : 'details-container-phone-box-btn',
          ]"
          @click="addPhoneNumberHandler"
        >
          <i class="far fa-arrow-right"></i>
        </div>
      </div>
    </dx-validation-group>
    <div class="details-container-phone-rodo">
      <span>
        Podaj numer telefonu aby otrzymać informację o zakończeniu naprawy
        Twojego pojazdu. Twoje dane osobowe przetwarzane są przez serwis zgodnie
        z obowiązkiem informacyjnym, z którym zapoznałeś się w chwili oddania
        auta do naprawy.
      </span>
    </div>
    <dx-popup
      :drag-enabled="false"
      :close-on-outside-click="true"
      height="auto"
      :show-title="false"
      class="custom-popup"
      width="80%"
      :visible="isPopupVisible"
      @hiding="isPopupVisible = false"
    >
      <div class="popup-container" @click="isPopupVisible = false">
        <i class="close-icon far fa-times"></i>
        <i class="icon success far fa-check-circle"></i>
        <h4 class="message">
          Na podany numer telefonu wysłaliśmy informację. Sprawdź czy ją
          otrzymałeś. Jeśli nie to skontaktuj się z nami <a>kontakt@naprawaonline.pl</a>.
        </h4>
      </div>
    </dx-popup>
  </div>
</template>

<script>
import { DxPopup } from "devextreme-vue/popup";
import DxTextBox from "devextreme-vue/text-box";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxRequiredRule, DxValidator } from "devextreme-vue/validator";

import { mapActions } from "vuex";

export default {
  name: "DetailsPhone",
  components: {
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationGroup,
    DxPopup,
  },
  data() {
    return {
      phoneNumber: null,
      isValidNumber: false,
      isPopupVisible: false,
    };
  },
  methods: {
    ...mapActions(["addPhoneNumber"]),
    addPhoneNumberHandler: function () {
      if (this.isValidNumber) {
        this.addPhoneNumber(this.phoneNumber).then(() => {
          this.isPopupVisible = true;
          this.phoneNumber = null;
          this.$refs["validationGroup"].instance.reset();
        });
      }
    },
    inputPhoneNumberHandler: function () {
      let validateResult = this.$refs["validationGroup"].instance.validate();
      if (validateResult.isValid) {
        this.isValidNumber = true;
      } else {
        this.isValidNumber = false;
      }
    },
  },
};
</script>
