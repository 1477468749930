var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-container-phone"},[_vm._m(0),_c('dx-validation-group',{ref:`validationGroup`},[_c('div',{staticClass:"details-container-phone-box"},[_c('dx-text-box',{attrs:{"height":"42px","placeholder":"Numer telefonu...","width":"85%","mode":"tel","mask":"+48 000 000 000","mask-invalid-message":"Pole wymagane","value-change-event":"keyup"},on:{"keyUp":_vm.inputPhoneNumberHandler},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}},[_c('dx-validator',[_c('dx-required-rule',{attrs:{"message":"Pole wymagane"}})],1)],1),_c('div',{class:[
          _vm.isValidNumber
            ? 'details-container-phone-box-btn active'
            : 'details-container-phone-box-btn',
        ],on:{"click":_vm.addPhoneNumberHandler}},[_c('i',{staticClass:"far fa-arrow-right"})])],1)]),_vm._m(1),_c('dx-popup',{staticClass:"custom-popup",attrs:{"drag-enabled":false,"close-on-outside-click":true,"height":"auto","show-title":false,"width":"80%","visible":_vm.isPopupVisible},on:{"hiding":function($event){_vm.isPopupVisible = false}}},[_c('div',{staticClass:"popup-container",on:{"click":function($event){_vm.isPopupVisible = false}}},[_c('i',{staticClass:"close-icon far fa-times"}),_c('i',{staticClass:"icon success far fa-check-circle"}),_c('h4',{staticClass:"message"},[_vm._v(" Na podany numer telefonu wysłaliśmy informację. Sprawdź czy ją otrzymałeś. Jeśli nie to skontaktuj się z nami "),_c('a',[_vm._v("kontakt@naprawaonline.pl")]),_vm._v(". ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-container-phone-label"},[_c('span',[_vm._v(" Wpisz numer telefonu aby "),_c('br'),_vm._v(" otrzymać SMS po zakończeniu naprawy: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-container-phone-rodo"},[_c('span',[_vm._v(" Podaj numer telefonu aby otrzymać informację o zakończeniu naprawy Twojego pojazdu. Twoje dane osobowe przetwarzane są przez serwis zgodnie z obowiązkiem informacyjnym, z którym zapoznałeś się w chwili oddania auta do naprawy. ")])])
}]

export { render, staticRenderFns }