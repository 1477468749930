<template>
  <div class="details-container-status">
    <h4>Status naprawy:</h4>
    <h2 v-if="!getIsFinishedStatus">{{ getStatus }}</h2>
    <h2 v-else class="done">{{ getStatus }}</h2>
    <i v-if="!getIsFinishedStatus" class="far fa-spinner fa-pulse"></i>
    <i v-else class="far fa-check-circle done"></i>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DetailsStatus",
  computed: {
    ...mapGetters(["getStatus", "getIsFinishedStatus"]),
  },
};
</script>
